import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './Styles/base-theme.scss'
import Booking from './Bookings/Booking'
import Payment from './Bookings/Elements/Payment/Payment'
import PaymentCallback from './Bookings/Elements/Payment/PaymentCallback'
import PaymentCheck from './Bookings/Elements/Payment/PaymentCheck'
import PayBooking from './Bookings/Elements/Payment/PayBooking'
import JoinBooking from './Bookings/JoinBooking'
import DepositRequest from './Bookings/Deposits/DepositRequest'
import PortalPage from './Portal/PortalPage'
import Login from './Portal/Authentication/Login'
import BookingGuest from './Bookings/Guests/BookingGuest'
import NotFound from './Portal/Errors/NotFound'
import PaymentRequest from './Bookings/Deposits/PaymentRequest'
import Register from './Portal/Authentication/Register'
import Verify from './Portal/Authentication/Verify'
import Dashboard from './Portal/Dashboard/Dashboard'
import MyDetails from './Portal/MyDetails/MyDetails'
import EditBooking from './Portal/EditBooking/EditBooking'
import ReBook from './Portal/ReBook/ReBook'
import NewBooking from './Portal/NewBooking/NewBooking'
import Logout from './Portal/Authentication/Logout'
import ForgotPassword from './Portal/Authentication/ForgotPassword'
import VerifyForgotPassword from './Portal/Authentication/VerifyForgotPassword'
import CovidCheckin from './Portal/CovidCheckin/CovidCheckin'
import GuestJoinBooking from './Portal/GuestJoinBooking/GuestJoinBooking'
import NewAppBooking from './Portal/NewAppBooking/NewAppBooking'
import ViewBar from './Bars/ViewBar'
import EditBookingStandalone from './Portal/EditBooking/Standalone/EditBookingStandalone'
import TrackTrace from './Bars/TrackTrace'
import ClubSocRegistration from './Loyalty/ClubSocRegistration'
import JoinSocietyQr from './Loyalty/Society/JoinSocietyQr'
import SocietyDashboard from './Loyalty/Society/SocietyDashboard'
import SocietyAdmin from './Loyalty/Society/SocietyAdmin'
import SignContract from './Loyalty/Society/SignContract'
import ContractCopy from './Loyalty/Society/ContractCopy'
import MenuAllergens from './Menus/MenuAllergens'
import StaffTip from './Bookings/Elements/StaffTips/StaffTip'
import StaffTipComplete from './Bookings/Elements/StaffTips/StaffTipComplete'
import StudentSignup from "./Portal/Student/StudentSignup";
import StaffLogin from "./Authentication/StaffLogin";
import DataCollection from "./DataCollection/DataCollection";
import StaffLogout from "./Authentication/StaffLogout";
import EmailVerification from "./EmailVerification/EmailVerification";
import AppVoucherRedemption from "./Vouchers/AppVoucherRedemption";
import {Api} from "./Api/Api";
import {QueryClient, QueryClientProvider} from "react-query";
import GraduateStatus from "./Portal/Student/GraduateStatus";
import IcsCalendar from "./Bookings/Elements/icsCalendar/IcsCalendar";
import AllergensMenu from './Menus/AllergensMenu/AllergensMenu'

function App () {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 0,
                refetchOnWindowFocus: false,
                initialData: {
                    data: [],
                    meta: {pagination: {}}
                },
                queryFn: Api.reactQueryFn
            }
        }
    });
    return (
        <QueryClientProvider client={queryClient}>
      <Router>
          <div>
              <Switch>
                  <Route path="/bar/:slug" component={(props) => <PortalPage {...props} name="deposit-request"
                                                                             component={<ViewBar {...props}
                                                                                                 slug={props.match.params.slug}/>}/>}
                         exact={true}/>

                  <Route path="/clubsoc/:userid/:email?" component={(props) => <PortalPage {...props} name="clubsoc"
                                                                                          component={
                                                                                              <ClubSocRegistration {...props}
                                                                                                                   email={props.match.params.email}
                                                                                                                   user_id={props.match.params.userid}/>}/>}
                         exact={true}/>
                  <Route path="/track-trace/:bar" component={(props) => <PortalPage {...props} name="deposit-request"
                                                                                    component={<TrackTrace {...props}
                                                                                                           bar={props.match.params.bar}/>}/>}/>
                  <Route path="/booking" component={Booking} exact={true}/>
                  <Route path="/pay-deposit/:code" component={(props) => <PayBooking {...props} type="deposit"/>}/>
                  <Route path="/deposit/:code" component={(props) => <PortalPage {...props} name="deposit-request"
                                                                                 component={<DepositRequest {...props}
                                                                                                            code={props.match.params.code}/>}/>}
                         exact={true}/>
                  <Route path="/pay" component={(props) => <PortalPage {...props}
                                                                       component={<PaymentRequest {...props}/>}/>}
                         exact={true}/>
                  <Route path="/tip/:site_code?" component={(props) => <PortalPage {...props}
                                                                                   component={<StaffTip {...props}/>}/>}
                         exact={true}/>
                  <Route path="/tip/complete/:id" component={(props) => <PortalPage {...props}
                                                                                    component={
                                                                                        <StaffTipComplete {...props}/>}/>}
                         exact={true}/>
                  <Route path="/payment/:code" component={Payment} exact={true}/>
                  <Route path="/payment/:id" component={PaymentCallback} exact={true}/>
                  <Route path="/payment/check/:code" component={PaymentCheck} exact={true}/>
                  <Route path="/manage/:token/:action?/:sub_action?"
                         component={(props) => <PortalPage {...props} name="edit-booking" requires_login={false}
                                                           component={<EditBookingStandalone {...props}
                                                                                             code={props.match.params.token}
                                                                                             action={props.match.params.action}
                                                                                             sub_action={props.match.params.sub_action}/>}/>}/>
                  <Route path="/ical/:token" component={(props) => <IcsCalendar token={props.match.params.token} />}/>
                  <Route path="/join/:token/:variation?"
                         component={(props) => <PortalPage {...props} name="join-booking"
                                                           component={<JoinBooking {...props}
                                                                                   code={props.match.params.token}
                                                                                   variation={props.match.params.variation}
                                                           />}/>}/>
                  <Route path="/guest-join/:token"
                         component={(props) => <PortalPage {...props} name="join-booking-guest"
                                                           component={<GuestJoinBooking {...props}
                                                                                        code={props.match.params.token}/>}/>}/>
                  <Route path="/guest/:token"
                         component={(props) => <PortalPage {...props} component={<BookingGuest {...props}
                                                                                               code={props.match.params.token}/>}/>}/>
                  <Route path="/login/:token?" exact={true}
                         component={(props) => <PortalPage {...props} name="login"
                                                           component={<Login {...props}
                                                                             join_token={props.match.params.token}/>}/>}/>
                  <Route path="/forgot-password" exact={true}
                         component={(props) => <PortalPage {...props} name="login"
                                                           component={<ForgotPassword {...props}/>}/>}/>
                  <Route path="/password-reset/:token" exact={true}
                         component={(props) => <PortalPage {...props} name="login"
                                                           component={<VerifyForgotPassword
                                                             token={props.match.params.token} {...props}/>}/>}/>
                  <Route path="/logout" exact={true}
                         component={(props) => <Logout/>}/>
                  <Route path="/register/:token?" exact={true}
                         component={(props) => <PortalPage {...props} name="register"
                                                           component={<Register {...props}
                                                                                join_token={props.match.params.token}/>}/>}/>
                  <Route path="/edit-booking/:id" exact={true}
                         component={(props) => <PortalPage {...props} name="edit-booking" variant="variant"
                                                           requires_login={true}
                                                           component={<EditBooking {...props}
                                                                                   id={props.match.params.id}/>}/>}/>
                  <Route path="/new-booking" exact={true}
                         component={(props) => <PortalPage {...props} name="new-booking" variant="variant"
                                                           requires_login={true}
                                                           component={<NewBooking {...props} />}/>}/>
                  <Route path="/new-booking-debug" exact={true}
                         component={(props) => <PortalPage {...props} name="new-booking" variant="variant"
                                                           requires_login={true}
                                                           component={<NewBooking {...props} debug={true}/>}/>}/>
                  <Route path="/staff/login" exact={true}
                         component={(props) => <PortalPage {...props} name="staff-login" variant="variant"
                                                           component={<StaffLogin {...props} debug={true}/>}/>}/>
                  <Route path="/staff/logout" exact={true}
                         component={(props) => <PortalPage {...props} name="staff-logout" variant="variant"
                                                           component={<StaffLogout {...props} debug={true}/>}/>}/>
                  <Route path="/student-signup/:bar_id?" exact={true}
                         component={(props) => <PortalPage {...props} name="staff-login" variant="variant"
                                                           component={<DataCollection {...props}
                                                                                      bar_id={props.match.params.bar_id}
                                                                                      debug={true}/>}/>}/>
                  <Route path="/re-book/:token" exact={true}
                         component={(props) => <PortalPage {...props} name="edit-booking" requires_login={false}
                                                           component={<ReBook {...props}
                                                                              token={props.match.params.token}/>}/>}/>
                  <Route path="/verify/:token/:join_token?" exact={true}
                         component={(props) => <PortalPage {...props} name="verify"
                                                           component={<Verify
                                                             token={props.match.params.token}
                                                             join_token={props.match.params.join_token} {...props}/>}/>}/>
                  <Route path="/my-details" exact={true}
                         component={(props) => <PortalPage {...props} name="my-details" requires_login={true}
                                                           component={<MyDetails {...props}/>}/>}/>
                  <Route path="/" exact={true}
                         component={(props) => <PortalPage {...props} name="dashboard" requires_login={true}
                                                           component={<Dashboard {...props}/>}/>}/>
                  <Route path="/app/:app_user_id" component={(props) => <PortalPage {...props} name="dashboard" requires_login={true} app_user={true}
                                                                                     component={<Dashboard app_user={true} {...props}/>}/>} />
                  <Route path="/book-app/:app_id/:bar_id?"
                         component={(props) => <NewAppBooking {...props} data={{
                             app_id: props.match.params.app_id,
                             bar_id: props.match.params.bar_id,
                         }}/>}/>
                  <Route path="/student/:user_id/:email?/"
                         component={(props) => <StudentSignup {...props} user_id={props.match.params.user_id}
                                                              email={props.match.params.email} data={{
                             user_id: props.match.params.user_id,
                             email: props.match.params.email,
                         }}/>}/>
                  <Route path="/graduate/:user_id/:email?/"
                         component={(props) => <GraduateStatus {...props} user_id={props.match.params.user_id}
                                                              email={props.match.params.email} data={{
                             user_id: props.match.params.user_id,
                             email: props.match.params.email,
                         }}/>}/>
                  <Route path="/email-verify/:code"
                         component={(props) => <EmailVerification {...props} code={props.match.params.code}/>}/>
                  <Route path="/covid-checkin"
                         component={(props) => <PortalPage {...props} name="covid-login" requires_login={false}
                                                           component={<CovidCheckin {...props}/>}/>}/>
                  <Route path="/society/qr/:code"
                         component={(props) => <PortalPage {...props} name="society-qr" requires_login={false}
                                                           component={<JoinSocietyQr {...props}/>}/>}/>
                  <Route path="/society" exact={true}
                         component={(props) => <PortalPage {...props} name="society-dashboard" requires_login={true}
                                                           component={<SocietyDashboard {...props}/>}/>}/>
                  <Route path="/society/:id" exact={true}
                         component={(props) => <PortalPage {...props} name="society-admin" requires_login={true}
                                                           component={<SocietyAdmin {...props}/>}/>}/>
                  <Route path="/society/contract/:code/:admin_code" exact={true}
                         component={(props) => <PortalPage {...props} name="society-contract" requires_login={false}
                                                           component={<SignContract {...props}/>}/>}/>
                  <Route path="/society/contract-copy/:code/:admin_code" exact={true}
                         component={(props) => <ContractCopy {...props}/>}/>
                  <Route path="/app-voucher/:app_user_id" exact={true}
                         component={(props) => <AppVoucherRedemption {...props}/>}/>
                  <Route
                      path="/allergens"
                      exact={true}
                      component={
                        (props) =>
                            <PortalPage {...props} name='login' component={<AllergensMenu />} />
                      }
                  />
                  <Route path="/allergens-pdf/:type?/:bar_id?" exact={true}
                         component={(props) => <PortalPage {...props} name="login"
                                                           component={<MenuAllergens
                                                             bar_id={props.match.params.bar_id}
                                                             type={props.match.params.type} {...props}/>}/>}/>
                  <Route path="/book" exact={true}
                         component={(props) => <PortalPage {...props} name="new-booking" variant="variant"
                                                           requires_login={false}
                                                           component={<NewBooking {...props} />}/>}/>
                  <Route component={(props) => <PortalPage {...props} component={<NotFound {...props}/>}/>}
                         status={404}/>
              </Switch>
          </div>
      </Router>
        </QueryClientProvider>
    )
}

export default App
